import { addIndex, compose, is, map, split } from "ramda"
import { lNumber, tokenized, ninjaReportError, isProdEnvironment } from "js/includes/common/utils/ssrAndWebUtils"
import { getApplicationLanguage, getEngTextToken, isDefaultLanguage, getResultToken } from "./langAndTokens"

// _localized is our main function for making localization.
// Please use alias localized func from autocomplete.js for autocompletion feature!
// It supports both old and new variants of localization:
// old deprecated (with tokens):
//    localized("general.moreInfo")
// new recommended (with English text as a key):
//    localized("More Information")
// This variant of localized (support old & new) is created for soft migration from old localization system,
// based on tokens, to the new localization system, where English text is the key itself for non-default languages.
// Try to avoid using old-style syntax of localized with Tokens like localized("general.moreInfo"),
// and use instead English text where possible like localized("More Information").
export function _localized(token, vals) {
  try {
    if (!isNaN(token) && typeof token === "number") {
      const isExponential = token.toString().includes("e")
      if (isExponential) return token

      return lNumber(token, { ...vals, language: getApplicationLanguage() })
    }

    if (typeof token !== "string") {
      if (!isProdEnvironment(window.store.getState().application.environment)) {
        ninjaReportError(`try to call localized with non-string token of type: ${typeof token}`)
      }
      return ""
    }

    const engTextToken = getEngTextToken(token)
    const resultToken = isDefaultLanguage() ? engTextToken : getResultToken(engTextToken)

    if (arguments.length === 1) {
      return resultToken // regular most used case, when we need to localize English text
    }

    //for dynamic
    return tokenized(resultToken, vals)
  } catch (e) {
    ninjaReportError(e)
    if (typeof token === "string") {
      return token
    }
    return ""
  }
}

const mapIndexed = addIndex(map)
export const tokenizedWith = (str, formatters = {}) =>
  compose(
    mapIndexed((substr, index) => {
      const [, formatterName, localizedText] = substr.match(/^<%(.+)>(.+)<%>/) ?? []
      const dictVal = substr.includes("<%") ? formatters[formatterName] : substr
      return (
        <span key={`${index}-${substr}`}>
          {dictVal === undefined ? substr : is(Function, dictVal) ? dictVal({ localizedText }) : dictVal}
        </span>
      )
    }),
    split(/(<%.*?<%>)/),
  )(str)

export function _localizedWith(token, vals) {
  try {
    const engTextToken = getEngTextToken(token)
    if (isDefaultLanguage()) {
      return tokenizedWith(engTextToken, vals)
    }
    return tokenizedWith(getResultToken(engTextToken), vals)
  } catch (e) {
    ninjaReportError(e)
    if (typeof token === "string") {
      return token
    }
    return ""
  }
}
